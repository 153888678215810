import { Switch } from "../Service/Switch.js"
import { Icons } from "../Service/Icons.js"
import { Cookies } from "../Service/Cookies.js"
import { Tabs } from "../Service/Tabs.js"

export class Application
{
    constructor(store)
    {
        this.store = store

        this.store.service.Switch = new Switch()
        this.store.service.Icons = new Icons()
        this.store.service.Cookies = new Cookies()
        this.store.service.Tabs = new Tabs()
    }

    run()
    {
        this.store.service.Switch.watch()
        this.store.service.Icons.run()
        this.store.service.Icons.watch()
        this.store.service.Cookies.run()
        this.store.service.Cookies.watch()
        this.store.service.Tabs.run()
        this.store.service.Tabs.watch()

        console.log("ehrthrt")
    }
}
